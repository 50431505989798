const theme = {
    "token": {
        "wireframe": false,
        "colorPrimary": "#946d6a",
        colorLink: "#946d6a",
    },
    "components": {
        "Input": {
            "colorBgContainer": "#f0f0f0"
        },
        "Select": {
            "colorBgContainer": "#f0f0f0"
        },
        "Radio": {
            "colorBgContainer": "#f0f0f0"
        },
        "Checkbox": {
            "colorBgContainer": "#f0f0f0"
        },
        "InputNumber": {
            "colorBgContainer": "#f0f0f0"
        },

        "Divider": {
            "colorSplit": "#000"
        },
        "Typography": {
            "fontSizeHeading1": 35
        }
    }
    // 
};
export default theme;