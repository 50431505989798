import React, { useState } from "react";
import { API_ROOT } from './api_root';
import { Button, Input, Space, message } from "antd";
import logo from "./assets/images/logo-desktop.svg";
import { CheckCircleOutlined } from '@ant-design/icons';


import "antd/dist/reset.css";
import "./styles/App.css";
import "./styles/fonts.css";
import "./styles/global-antd-overrides.scss";

import { ConfigProvider } from "antd";

import theme from "./styles/theme";
import deDE from "antd/locale/de_DE";


function App() {
  const [token, setToken] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState("");
  const [b2bAppDownloaded, setB2bAppDownloaded] = useState(false);
  const [scanAppDownloaded, setScanAppDownloaded] = useState(false);
  const [appType, setAppType] = useState("b2b_app");

  const handleAuth = async () => {
    message.loading({ content: "Authentifizierung...", key: "auth" });
    try {
      const response = await fetch(`${API_ROOT}/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ api_key: apiKey }),
      });
      if (response.status !== 200) {
        message.error({ content: "Authentifizierung fehlgeschlagen", key: "auth" });
        console.error("Authentication failed");
        return;
      }
      const data = await response.json();
      const { access_token, app_type } = data;
      message.success({ content: "Authentifiziert", key: "auth" });
      setToken(access_token);
      setAppType(app_type);
    } catch (error) {
      message.error({ content: "Authentifizierung fehlgeschlagen", key: "auth" });
    }
  };

  const download = async (path: string) => {
    message.loading({ content: "Download wird gestartet...", key: "b2b_app" });
    try {
      const response = await fetch(`${API_ROOT}/${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        console.error("Unauthorized request");
        setToken(null);
        return;
      }
      const data = await response.json();
      if ("link" in data) {
        message.success({ content: "Download gestartet", key: "b2b_app" });
        window.open(data.link, "_blank");
      } else {
        message.error({ content: "Download fehlgeschlagen", key: "b2b_app" });
      }
    } catch (error) {
      message.error({ content: "Download fehlgeschlagen", key: "b2b_app" });
    }
  };

  return (
    <>
      <ConfigProvider theme={theme} locale={deDE}>
        <Space className="Header" style={{ display: 'flex', justifyContent: 'space-between' }} >
          <div style={{ paddingLeft: "5vw" }}>
            <img src={logo} alt="logo" />
          </div>
        </Space >
        <div className="content" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          {!token ? (
            <div style={{ padding: 16, marginTop: 50 }}>
              <Input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter Password"
              />
              <Button style={{ marginTop: 16 }} onClick={handleAuth}>Authentifizieren</Button>
            </div>
          ) : (
            appType === 'b2b' ?
              <div style={{ padding: 16, marginTop: 50, width: 250 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button type="link" onClick={() => {
                    download('download_b2b_app')
                    setB2bAppDownloaded(true)
                  }}>B2B-App herunterladen</Button>
                  {b2bAppDownloaded && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button type="link" onClick={() => {
                    download('download_scan_app')
                    setScanAppDownloaded(true)
                  }}>Scan-App herunterladen</Button>
                  {scanAppDownloaded && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
                </div>
              </div>
              : <div style={{ padding: 16, marginTop: 50, width: 250 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button type="link" onClick={() => {
                    download('download_atlas_app')
                    setB2bAppDownloaded(true)
                  }}>Fit-App herunterladen</Button>
                  {b2bAppDownloaded && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button type="link" onClick={() => {
                    download('download_scan_app_atlas')
                    setScanAppDownloaded(true)
                  }}>Scan-App herunterladen</Button>
                  {scanAppDownloaded && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
                </div>
              </div>
          )}
        </div >
      </ConfigProvider >
    </>
  );
}

export default App;
